import $ from 'jquery';
import { publish, unpublish, subscribe } from 'Util/pubsub';
import { throttle } from 'Util/throttle';

var $window = $(window);

// Publish Resize event
var publishResizeEvent = function () {
	publish('/window/resize');
};

var publishScrollEvent = function () {
	publish('/window/scroll');
};

var themeName = $('body').attr('data-theme');

if (typeof themeName !== 'undefined') {
	if (themeName === 'default') {
		import(/* webpackChunkName: "DefaultPage" */ 'Page/default').then(({ DefaultPage }) => {
			DefaultPage.init();
		});
	} else if (themeName === 'expert') {
		import(/* webpackChunkName: "ExpertPage" */ 'Page/expert').then(({ ExpertPage }) => {
			ExpertPage.init();
		});
	}
}

$(document).ready(function () {
	var html = $('.containerWrapper').html();
	if (html) {
		html.replace(/\u200B/g, '');
	}
});

$window.on('resize', throttle(publishResizeEvent));
$window.on('scroll', publishScrollEvent);